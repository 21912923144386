import http from '../utils/http'
import conf from '../config'

export const API_ROOT = `${conf.API_URL}`

const UserGuideServices = {
    findUserGuides() {
        return http.get(`${API_ROOT}/userGuides`).then(resp => {
            return resp
        })
    },
    getUserGuide(userType) {
        return http.get(`${API_ROOT}/userGuides/${userType}`).then(resp => {
            return resp
        })
    },
    updateUserGuides(payload) {
        return http.put(`${API_ROOT}/userGuides/${payload.user_guide_id}`, payload).then(resp => {
            return resp
        })
    },
    createUserGuides(payload) {
        return http.post(`${API_ROOT}/userGuides`, payload).then(resp => {
            return resp
        })
    },
    deleteUserGuides(payload) {
        return http.delete(`${API_ROOT}/userGuides/${payload.fuser_guide_id}`,).then(resp => {
            return resp
        })
    },
}

export default UserGuideServices
