import UserGuideServices from '../../../services/user-guide'
import {getUserType } from '../../../misc/auth'
export default {
    data() {
        return {
            item: []
        }
    },
    mounted() {
        this.getUserGuide()
    },
    methods: {
        getUserGuide() {
            UserGuideServices.getUserGuide(getUserType()).then(resp => {
                if (!resp.error) {
                    this.item = resp.data.d
                }
            })
        }
    }
}